import React from 'react';

import SEO from '../components/layout/SEO';

const redirect =
    'https://api.whatsapp.com/send?text=I%E2%80%99ve%20just%20joined%20Roundups%2C%20the%20penny%20donation%20app.%20Roundups%20links%20with%20my%20card%2C%20rounds%20up%20all%20of%20my%20payments%20and%20automatically%20donates%20pennies%20to%20my%20favourite%20charity%20as%20I%20spend.%20You%20can%20track%20your%20impact%20inside%20the%20app%20and%20barely%20notice%20the%20pennies%20going%20out.%20%0A%0AIt%E2%80%99s%20a%20smart%20and%20simple%20way%20to%20donate%20and%20it%E2%80%99s%20really%20easy%20to%20get%20started.%20%0A%0AJoin%20at%20https%3A%2F%2Fwww.joinripples.org%2F%23join%0A';

const Whatsapp = () => <SEO allowIndexing={false} redirect={redirect} />;

export default Whatsapp;
